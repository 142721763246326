<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.expenses") }}
          <v-spacer />

          <id-button class="mr-2" @click="onCreate">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("hr.expense.create_expense") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.type.name }}</td>
              <td>{{ $t(`hr.expense.payment_type.${row.item.paymentType}`) }}</td>

              <td>{{ $helpers.getConvertCurrency(row.item.requested) }}</td>
              <td>
                {{ row.item.timestamp | formattedDate }}
              </td>
              <td>{{ row.item.description || "-" }}</td>
              <td>
                <template v-if="row.item.travel">
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-account</v-icon>
                    {{ row.item.travel.employee.firstName }} {{ row.item.travel.employee.lastName }}
                  </p>
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-map-marker</v-icon>{{ row.item.travel.address }}
                  </p>
                  <p class="ma-0 caption">
                    <v-icon size="20" color="teal">mdi-calendar-range</v-icon>
                    {{ row.item.travel.beginDate | formattedDateTimeMinute }} -
                    {{ row.item.travel.endDate | formattedDateTimeMinute }}
                  </p>
                </template>
                <v-icon v-else color="red">mdi-close</v-icon>
              </td>
              <td>
                <template v-if="row.item.expenseManagement">
                  <p class="ma-0">
                    {{ row.item.expenseManagement.name }}
                  </p>
                  <p v-if="row.item.expenseManagement.project" class="ma-0 caption">
                    <v-avatar size="14" :color="row.item.expenseManagement.project.color" />
                    {{ row.item.expenseManagement.project.name }}
                  </p>
                </template>
                <v-icon v-else color="red">mdi-close</v-icon>
              </td>
              <td>
                <request-status-label :status="row.item.requestStatus" />
              </td>
              <td>
                <file-download-button :url="row.item.attachment" />
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { EXPENSES } from "./query";
  import RequestStatusLabel from "@/components/global/request-status-label";

  export default {
    name: "Expense",
    components: { RequestStatusLabel },
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: vm.$store.state.auth.user.id
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "timestamp",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.expense.name"),
            value: "name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.type"),
            value: "type.name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.paymentType"),
            value: "paymentType",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.expense.amount"),
            value: "requested.amount",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.date"),
            value: "timestamp",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.description"),
            value: "description",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.travel"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.expense_management"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.expense.status"),
            value: "requestStatus",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"],
            width: "5%"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: EXPENSES,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.filterWithPaginateExpenses.content || [];
              this.table.totalItems = data.filterWithPaginateExpenses.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.replace({ name: "expense_create" });
      }
    }
  };
</script>

<style scoped></style>
